
//RoyalBanquet
import RB1 from '../../../Assets/photos/Royal/1.jpeg'
import RB2 from '../../../Assets/photos/Royal/2.jpeg'
import RB3 from '../../../Assets/photos/Royal/3.jpeg'
import RB4 from '../../../Assets/photos/Royal/4.jpeg'
import RB5 from '../../../Assets/photos/Royal/5.jpeg'
import RB6 from '../../../Assets/photos/Royal/6.jpeg'
import RB7 from '../../../Assets/photos/Royal/7.jpeg'
import RB8 from '../../../Assets/photos/Royal/8.jpeg'
import RB9 from '../../../Assets/photos/Royal/9.jpeg'
import RB10 from '../../../Assets/photos/Royal/10.jpeg'
import RB11 from '../../../Assets/photos/Royal/11.jpeg'
import RB12 from '../../../Assets/photos/Royal/12.jpeg'
//Shantibon
import SB1 from '../../../Assets/photos/Shantibon/1.jpeg';
import SB2 from '../../../Assets/photos/Shantibon/2.jpeg';
 import SB3 from '../../../Assets/photos/Shantibon/3.jpeg';
 import SB4 from '../../../Assets/photos/Shantibon/4.jpeg';
import SB5 from '../../../Assets/photos/Shantibon/5.jpeg';
import SB6 from '../../../Assets/photos/Shantibon/6.jpeg';
import SB7 from '../../../Assets/photos/Shantibon/7.jpeg';
import SB8 from '../../../Assets/photos/Shantibon/8.jpeg';
import SB9 from '../../../Assets/photos/Shantibon/9.jpeg';
import SB10 from '../../../Assets/photos/Shantibon/10.jpeg';
import SB11 from '../../../Assets/photos/Shantibon/11.jpeg';
import SB12 from '../../../Assets/photos/Shantibon/12.jpeg';
import SB13 from '../../../Assets/photos/Shantibon/13.jpeg';
import SB14 from '../../../Assets/photos/Shantibon/14.jpeg';
import SB15 from '../../../Assets/photos/Shantibon/15.jpeg';
import SB16 from '../../../Assets/photos/Shantibon/16.jpeg';
import SB17 from '../../../Assets/photos/Shantibon/17.jpeg';
import SB18 from '../../../Assets/photos/Shantibon/18.jpeg';
import SB19 from '../../../Assets/photos/Shantibon/19.jpeg';
import SB20 from '../../../Assets/photos/Shantibon/20.jpeg';
import SB21 from '../../../Assets/photos/Shantibon/21.jpeg';
import SB22 from '../../../Assets/photos/Shantibon/22.jpeg';
import SB23 from '../../../Assets/photos/Shantibon/23.jpeg';
import SB24 from '../../../Assets/photos/Shantibon/24.jpeg';
import SB25 from '../../../Assets/photos/Shantibon/25.jpeg';
import SB26 from '../../../Assets/photos/Shantibon/26.jpeg';
import SB27 from '../../../Assets/photos/Shantibon/27.jpeg';
import SB28 from '../../../Assets/photos/Shantibon/28.jpeg';
import SB29 from '../../../Assets/photos/Shantibon/29.jpeg';
import SB30 from '../../../Assets/photos/Shantibon/30.jpeg';
import SB31 from '../../../Assets/photos/Shantibon/31.jpeg';
import SB32 from '../../../Assets/photos/Shantibon/32.jpeg';
import SB33 from '../../../Assets/photos/Shantibon/33.jpeg';
import SB34 from '../../../Assets/photos/Shantibon/34.jpeg';
import SB35 from '../../../Assets/photos/Shantibon/35.jpeg';
import SB36 from '../../../Assets/photos/Shantibon/36.jpeg';
import SB37 from '../../../Assets/photos/Shantibon/37.jpeg';
import SB38 from '../../../Assets/photos/Shantibon/38.jpeg';
import SB39 from '../../../Assets/photos/Shantibon/39.jpeg';
import SB40 from '../../../Assets/photos/Shantibon/40.jpeg';
import SB41 from '../../../Assets/photos/Shantibon/41.jpeg';
import SB42 from '../../../Assets/photos/Shantibon/42.jpeg';
import SB43 from '../../../Assets/photos/Shantibon/43.jpeg';
import SB44 from '../../../Assets/photos/Shantibon/44.jpeg';
import SB45 from '../../../Assets/photos/Shantibon/45.jpeg';
import SB46 from '../../../Assets/photos/Shantibon/46.jpeg';
import SB47 from '../../../Assets/photos/Shantibon/47.jpeg';
import SB48 from '../../../Assets/photos/Shantibon/48.jpeg';
import SB49 from '../../../Assets/photos/Shantibon/49.jpeg';
import SB50 from '../../../Assets/photos/Shantibon/50.jpeg';
import SB51 from '../../../Assets/photos/Shantibon/51.jpeg';
import SB52 from '../../../Assets/photos/Shantibon/52.jpeg';
import SB53 from '../../../Assets/photos/Shantibon/53.jpeg';
import SB54 from '../../../Assets/photos/Shantibon/54.jpeg';
import SB55 from '../../../Assets/photos/Shantibon/55.jpeg';
// import SB56 from '../../Assets/photos/Shantibon/56.jpeg';
// import SB57 from '../../Assets/photos/Shantibon/57.jpeg';
// import SB58 from '../../Assets/photos/Shantibon/58.jpeg';
// import SB59 from '../../Assets/photos/Shantibon/59.jpeg';
// import SB60 from '../../Assets/photos/Shantibon/60.jpeg';
// import SB61 from '../../Assets/photos/Shantibon/61.jpeg';
// import SB62 from '../../Assets/photos/Shantibon/62.jpeg';
// import SB63 from '../../Assets/photos/Shantibon/63.jpeg';
// import SB64 from '../../Assets/photos/Shantibon/64.jpeg';
// import SB65 from '../../Assets/photos/Shantibon/65.jpeg';
// import SB66 from '../../Assets/photos/Shantibon/66.jpeg';
// import SB67 from '../../Assets/photos/Shantibon/67.jpeg';
// import SB68 from '../../Assets/photos/Shantibon/68.jpeg';
// import SB69 from '../../Assets/photos/Shantibon/69.jpeg';
// import SB70 from '../../Assets/photos/Shantibon/70.jpeg';
// import SB71 from '../../Assets/photos/Shantibon/71.jpeg';
// import SB72 from '../../Assets/photos/Shantibon/72.jpeg';
// import SB73 from '../../Assets/photos/Shantibon/73.jpeg';
// import SB74 from '../../Assets/photos/Shantibon/74.jpeg';
// import SB75 from '../../Assets/photos/Shantibon/75.jpeg';
// import SB76 from '../../Assets/photos/Shantibon/76.jpeg';
// import SB77 from '../../Assets/photos/Shantibon/77.jpeg';
// import SB78 from '../../Assets/photos/Shantibon/78.jpeg';
// import SB79 from '../../Assets/photos/Shantibon/79.jpeg';
// import SB80 from '../../Assets/photos/Shantibon/80.jpeg';
// import SB81 from '../../Assets/photos/Shantibon/81.jpeg';
// import SB82 from '../../Assets/photos/Shantibon/82.jpeg';
// import SB83 from '../../Assets/photos/Shantibon/83.jpeg';
// import SB84 from '../../Assets/photos/Shantibon/84.jpeg';
// import SB85 from '../../Assets/photos/Shantibon/85.jpeg';
// import SB86 from '../../Assets/photos/Shantibon/86.jpeg';
// import SB87 from '../../Assets/photos/Shantibon/87.jpeg';
// import SB88 from '../../Assets/photos/Shantibon/88.jpeg';



//SaiArati
import SA1 from '../../../Assets/photos/Sai Arati/1.jpeg';
import SA2 from '../../../Assets/photos/Sai Arati/2.jpeg';
import SA3 from '../../../Assets/photos/Sai Arati/3.jpeg';
import SA4 from '../../../Assets/photos/Sai Arati/4.jpeg';
import SA5 from '../../../Assets/photos/Sai Arati/5.jpeg';
import SA6 from '../../../Assets/photos/Sai Arati/6.jpeg';
import SA7 from '../../../Assets/photos/Sai Arati/7.jpeg';
import SA8 from '../../../Assets/photos/Sai Arati/8.jpeg';
import SA9 from '../../../Assets/photos/Sai Arati/9.jpeg';
import SA10 from '../../../Assets/photos/Sai Arati/10.jpeg';
import SA11 from '../../../Assets/photos/Sai Arati/11.jpeg';
import SA12 from '../../../Assets/photos/Sai Arati/12.jpeg';
import SA13 from '../../../Assets/photos/Sai Arati/13.jpeg';
import SA14 from '../../../Assets/photos/Sai Arati/14.jpeg';
import SA15 from '../../../Assets/photos/Sai Arati/15.jpeg';
import SA16 from '../../../Assets/photos/Sai Arati/16.jpeg';
import SA17 from '../../../Assets/photos/Sai Arati/17.jpeg';
import SA18 from '../../../Assets/photos/Sai Arati/18.jpeg';
import SA19 from '../../../Assets/photos/Sai Arati/19.jpeg';
import SA20 from '../../../Assets/photos/Sai Arati/20.jpeg';
import SA21 from '../../../Assets/photos/Sai Arati/21.jpeg';


 const   Wedding= [
      { id: 1, src: RB1 },
      { id: 2, src: RB2 },
      { id: 3, src: RB3 },
      { id: 4, src: SB1 },
      { id: 5, src: SB2 },
      { id: 6, src: SB3 },
      { id: 7, src: SA1 },
      { id: 8, src: SA2 },
      { id: 9, src: SA3 },
      { id: 10, src: RB4 },
      { id: 11, src: RB5 },
      { id: 12, src: SB4 },
      { id: 13, src: SA4 },
      { id: 14, src: SA5 },
    ]
    const   Receptions=[
      { id: 15, src: RB6 },
      { id: 16, src: RB7 },
      { id: 17, src: RB8 },
      { id: 18, src: SB5 },
      { id: 19, src: SB6 },
      { id: 20, src: SB7 },
      { id: 21, src: SA6 },
      { id: 22, src: SA7 },
      { id: 23, src: SA8 },
      { id: 24, src: SB8 },
      { id: 25, src: RB9 },
      { id: 26, src: SB9 },
      { id: 27, src: SA9 },
      { id: 28, src: SA10 },
    ]
    const      SangeetHaldi= [
      { id: 29, src: RB10 },
      { id: 30, src: SB10 },
      { id: 31, src: SB11 },
      { id: 32, src: SA11 },
      { id: 33, src: SA12 },
      { id: 34, src: SA13 },
      { id: 35, src: SB12 },
      { id: 36, src: RB11 },
      { id: 37, src: RB12 },
      { id: 38, src: SB13 },
      { id: 39, src: SA14 },
      { id: 40, src: SA15 },
      { id: 41, src: SA16 },
      { id: 42, src: SB14 },
    ]
    const     Birthdays= [
      { id: 43, src: SB15 },
      { id: 44, src: SB16 },
      { id: 45, src: SA17 },
      { id: 46, src: SA18 },
      { id: 47, src: SB17 },
      { id: 48, src: SA19 },
      { id: 49, src: SA20 },
      { id: 50, src: SB18 },
      { id: 51, src: SB19 },
      { id: 52, src: SA21 },
      { id: 53, src: SB20 },
      { id: 54, src: SB21 },
      { id: 55, src: SB22 },
      { id: 56, src: SB23 },
    ]
    const     CulturalEvents= [
      { id: 57, src: SB24 },
      { id: 58, src: SB25 },
      { id: 59, src: SB26 },
      { id: 60, src: SB27 },
      { id: 61, src: SB28 },
      { id: 62, src: SB29 },
      { id: 63, src: SB30 },
      { id: 64, src: SB31 },
      { id: 65, src: SB32 },
      { id: 66, src: SB33 },
      { id: 67, src: SB34 },
      { id: 68, src: SB35 },
      { id: 69, src: SB36 },
      { id: 70, src: SB37 },
    ]
    const     Parties= [
      { id: 71, src: SB38 },
      { id: 72, src: SB39 },
      { id: 73, src: SB40 },
      { id: 74, src: SB41 },
      { id: 75, src: SB42 },
      { id: 76, src: SB43 },
      { id: 77, src: SB44 },
      { id: 78, src: SB45 },
      { id: 79, src: SB46 },
      { id: 80, src: SB47 },
      { id: 81, src: SB48 },
      { id: 82, src: SB49 },
      { id: 83, src: SB50 },
      { id: 84, src: SB51 },
      { id: 85, src: SB52 },
      { id: 86, src: SB53 },
      { id: 87, src: SB54 },
      { id: 88, src: SB55 },
    ]
  
  
  export {Receptions,Wedding,Parties,CulturalEvents,Birthdays,SangeetHaldi};
  
  