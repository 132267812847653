// import React from 'react';
import './Blogs.css';

const Blogs = () => {
  // const blogPosts = [
  //   {
  //     id: 1,
  //     title: "Understanding React Components",
  //     excerpt: "Learn the basics of React components and how to structure them...",
  //     date: "October 15, 2024",
  //     author: "John Doe",
  //     authorImage: "https://via.placeholder.com/50"
  //   },
  //   {
  //     id: 2,
  //     title: "JavaScript ES6 Features",
  //     excerpt: "Explore the new features introduced in ES6, including arrow functions, destructuring, and more...",
  //     date: "September 25, 2024",
  //     author: "Jane Smith",
  //     authorImage: "https://via.placeholder.com/50"
  //   },
  //   {
  //     id: 3,
  //     title: "CSS Grid vs Flexbox",
  //     excerpt: "Which one should you use for layout? We compare CSS Grid and Flexbox...",
  //     date: "August 30, 2024",
  //     author: "Alice Johnson",
  //     authorImage: "https://via.placeholder.com/50"
  //   },
  // ];

  return (
    // <div className="blogs-container mt-[5.1rem]">
    //   <h1>Blogs</h1>
    //   <div>
    //     {blogPosts.map((post) => (
    //       <div key={post.id} className="blog-post">
    //         <div className="blog-post-header">
    //           <img src={post.authorImage} alt={`${post.author} profile`} className="author-image" />
    //           <div>
    //             <h2>{post.title}</h2>
    //             <p className="blog-post-author">{post.author}</p>
    //             <p className="blog-post-date">{post.date}</p>
    //           </div>
    //         </div>
    //         <p className="blog-post-excerpt">{post.excerpt}</p>
    //         <button className="read-more-btn">Read More</button>
    //       </div>
    //     ))}
    //   </div>
    // </div>
    <div className="blogs-container mt-[5.1rem] min-h-screen">
      <h1 className='text-3xl'>Blogs</h1>
      <p> This Page is Now Under Development</p>
    </div>
  );
};

export default Blogs;
