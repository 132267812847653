import DOE from '../../Assets/Aurodeep Paul.jpg';
import E1 from '../../Assets/Avi Das.jpeg';
import E2 from '../../Assets/Kartik Das.jpeg';
import E3 from '../../Assets/Atunu Mondal.jpeg';
import E4 from '../../Assets/Pritam Shrimani.jpeg';
import E5 from '../../Assets/Bappa Biswas.jpeg';
import E6 from '../../Assets/Ram Mirdha.jpeg';

const teamData = [
  {
    id: 1,
    name: 'Aurodeep Paul',
    role: 'Director Of Event',
    image: DOE,
    description: 'Aurodeep Paul is a visionary leader and the driving force behind Avishree Hospitality Pvt. Ltd., ensuring every event is executed with perfection.'
  },
  {
    id: 2,
    name: 'Avi Das',
    role: 'Event Crew',
    image: E1,
    description: 'Avi Das is a meticulous organizer, ensuring every aspect of the event runs smoothly and meets the highest standards of excellence.'
  },
  {
    id: 3,
    name: 'Kartik Das',
    role: 'Event Crew',
    image: E2,
    description: 'Kartik Das brings creativity and innovation to every event, specializing in unique and memorable experiences for our clients.'
  },
  {
    id: 4,
    name: 'Atunu Mondal',
    role: 'Event Crew',
    image: E3,
    description: 'Atunu Mondal excels in logistics and operations, making sure every event detail is flawlessly executed from start to finish.'
  },
  {
    id: 5,
    name: 'Pritam Shrimani',
    role: 'Event Crew',
    image: E4,
    description: 'Pritam Shrimani is passionate about guest satisfaction, always going the extra mile to ensure a delightful experience for all attendees.'
  },
  {
    id: 6,
    name: 'Bappa Biswas',
    role: 'Event Crew',
    image: E5,
    description: 'Bappa Biswas brings a wealth of experience in event management, ensuring that all aspects of the event are handled with utmost care and precision.'
  },
  {
    id: 7,
    name: 'Ram Mirdha',
    role: 'Event Crew',
    image: E6,
    description: 'Ram Mirdha is dedicated to creating seamless events with his strong attention to detail and exceptional coordination skills.'
  }
];

export default teamData;
