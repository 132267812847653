// import React from 'react'
import HomeCome from '../../Components/Home/HomeComponent';

const Home = () => {
  return (
    <>
    <HomeCome/>
    </>
  )
}


export default Home;